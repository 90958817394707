import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	ManyToOne,
	JoinColumn,
	OneToMany,
	ManyToMany,
	JoinTable,
	Index
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

import { Program, PublicProgram } from '../program/program.entity';
import { Invoice, PublicInvoice } from '../invoice/invoice.entity';
import { Cost, PublicCost } from '../cost/cost.entity';
import { Offer, PublicOffer } from '../offer/offer.entity';
import { TacticType, PublicTacticType } from '../tactic-type/tactic-type.entity';
import { TacticPhase, PublicTacticPhase } from '../tactic-phase/tactic-phase.entity';
import { PublicUser, User } from '../user/user.entity';
import { Note, PublicNote } from '../note/note.entity';
import { Tag, PublicTag } from '../tag/tag.entity';
import { Brand, PublicBrand } from '../brand/brand.entity';
import { FundingSource, PublicFundingSource } from '../funding-source/funding-source.entity';
import { Vendor, PublicVendor } from '../vendor/vendor.entity';
import { BudgetCache, PublicBudgetCache } from '../budget-cache/budget-cache.entity';
import { PublicWarning, Warning } from '../warning/warning.entity';
import { File, PublicFile } from '../file/file.entity';
import { Measurement, PublicMeasurement } from '../measurement/measurement.entity';
import { Milestone, PublicMilestone } from '../milestone/milestone.entity';
import { ExternalId, PublicExternalId } from '../external-id/external-id.entity';
import { CacheResultItem } from '../budget-cache/models/budget-cache.models';
import { BudgetCacheFragmentGenerator } from '../budget-cache/utils/budget-cache-fragment-generator';
import { SortHelper } from '../_core/decorators/sort-helper.decorator';
import { WorkflowOption } from '../integrations/vyc-commerce-platform/models';
import { Location, PublicLocation } from '../location/location.entity';

export type PublicTactic = Pick<
	Tactic,
	'id' | 'programId' | 'name' | 'detail' | 'created' |
	'tacticTypeId' | 'fundingSourceId' | 'locationId' | 'commercePlatformWorkflow' | 'created' | 'rmn' | 'dueDate'
	| 'nextSteps' | 'buySpecs' | 'flowchart' | 'landingPage'
> & {
	program?: PublicProgram,
	tacticType: PublicTacticType,
	tacticPhase: PublicTacticPhase,
	start?: string,
	end?: string,
	lastCouponExpiration?: string,
	fundingSource?: PublicFundingSource,
	location?: PublicLocation<any>,
	brands?: PublicBrand[],
	vendors?: PublicVendor[],
	destinationURL?: string,
	invoices?: PublicInvoice[],
	costs?: PublicCost[],
	budgetCache?: PublicBudgetCache,
	brandCaches?: PublicBudgetCache[],
	budgetCacheBrand?: CacheResultItem,
	offers?: PublicOffer[],
	notes?: PublicNote[],
	tags?: PublicTag[],
	author: PublicUser,
	warnings?: PublicWarning[],
	files?: PublicFile[],
	measurements?: PublicMeasurement[],
	milestone?: PublicMilestone[],
	externalIds?: PublicExternalId[],
	readOnly: boolean
	plannedOffersData?: any
};

@Entity('tactics')
@Index(['programId'])
export class Tactic {
	constructor(value?: Partial<Tactic>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: true })
	programId: string;
	@ManyToOne(
		type => Program,
		program => program.tactics,
		{
			// cascade: ['insert', 'remove'],
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'programId' })
	program: Program | Partial<Program>;

	@Column('text', { nullable: true })
	@Sortable
	name?: string;

	@Column('text', { nullable: true })
	detail?: string;

	@Column('text', { nullable: false })
	tacticTypeId: string;
	@ManyToOne(
		() => TacticType,
		{
			eager: true,
			nullable: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'tacticTypeId' })
	tacticType: TacticType | Partial<TacticType>;

	@Column('text', { nullable: false })
	tacticPhaseId: string;
	@ManyToOne(
		() => TacticPhase,
		{
			eager: true,
			nullable: false,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'tacticPhaseId' })
	tacticPhase: TacticPhase | Partial<TacticPhase>;

	@Column('text', { nullable: true })
	locationId?: string;
	@ManyToOne(
		() => Location,
		{
			eager: true,
			onDelete: 'SET NULL'
		}
	)
	@JoinColumn({ name: 'locationId' })
	location?: Location<any>;

	@Column('timestamptz', { nullable: true })
	@Sortable
	start?: string;

	@Column('timestamptz', { nullable: true })
	@Sortable
	end?: string;

	@Column('timestamptz', { nullable: true })
	lastCouponExpiration?: string;

	@ManyToMany(
		() => Vendor,
		{
			eager: true,
			nullable: false,
			cascade: true,
			onDelete: 'CASCADE'
		}
	)
	@JoinTable({ name: 'tacticVendors' })
	vendors: Vendor[] | Partial<Vendor>[];

	@ManyToMany(
		() => Brand,
		{
			eager: true,
			nullable: true,
			cascade: true
		}
	)
	@JoinTable({ name: 'tacticBrands' })
	brands?: Brand[] | Partial<Brand>[];

	@Column('text', { nullable: true })
	fundingSourceId: string;
	@ManyToOne(
		type => FundingSource,
		fundingSource => fundingSource.id,
		{
			nullable: true,
			eager: true
		}
	)
	@JoinColumn({ name: 'fundingSourceId' })
	fundingSource?: FundingSource | Partial<FundingSource>;

	@Column('text', { nullable: true })
	destinationURL?: string;

	@OneToMany(
		type => Invoice,
		invoice => invoice.tactic,
		{
			eager: true,
			nullable: true,
			cascade: true,
			onDelete: 'CASCADE'
		}
	)
	invoices?: Invoice[] | Partial<Invoice>[];

	@OneToMany(
		type => Cost,
		cost => cost.tactic,
		{
			eager: true,
			nullable: true,
			cascade: true,
			onDelete: 'CASCADE'
		}
	)
	costs?: Cost[] | Partial<Cost>[];

	@OneToMany(
		() => ExternalId,
		externalId => externalId.tactic,
		{
			eager: true,
			cascade: true
		}
	)
	externalIds?: ExternalId[] | Partial<ExternalId>[];

	@OneToMany(
		type => Offer,
		offer => offer.tactic,
		{
			eager: true,
			nullable: true,
			cascade: true,
			onDelete: 'CASCADE'
		}
	)
	offers?: Offer[] | Partial<Offer>[];

	@ManyToMany(
		() => Note,
		{
			eager: true,
			nullable: false,
			cascade: true
		}
	)
	@JoinTable({ name: 'tacticNotes' })
	notes?: Note[] | Partial<Note>[];

	@ManyToMany(
		() => Tag,
		{
			eager: true,
			nullable: false,
			cascade: true
		}
	)
	@JoinTable({ name: 'tacticTags' })
	tags?: Tag[] | Partial<Tag>[];

	@OneToMany(
		() => Measurement,
		measurement => measurement.tactic,
		{
			eager: true,
			cascade: true
		}
	)
	measurements?: Measurement[] | Partial<Measurement>[];

	@OneToMany(
		() => Measurement,
		milestone => milestone.tactic,
		{
			eager: true,
			cascade: true
		}
	)
	milestones?: Milestone[] | Partial<Milestone>[];

	@Column('jsonb', { nullable: true })
	commercePlatformWorkflow?: WorkflowOption;

	@Column('boolean', { default: false, nullable: false })
	deleted: boolean;

	@Column('boolean', {  nullable: true })
	rmn: boolean;

	@Column('timestamptz', {  nullable: true })
	dueDate: string;

	@Column('text', {  nullable: true })
	nextSteps: string;

	@Column('text', {  nullable: true })
	buySpecs: string;

	@Column('text', {  nullable: true })
	flowchart: string;

	@Column('text', {  nullable: true })
	landingPage: string;

	@Sortable
	@SortHelper(new BudgetCacheFragmentGenerator({ tacticMatch: true }))
	budgetCache?: BudgetCache;
	brandCaches?: CacheResultItem[];
	budgetCacheBrand?: CacheResultItem;

	@Column('text', { nullable: false })
	authorId: string;
	@ManyToOne(
		type => User,
		{
			eager: true,
			onDelete: 'CASCADE'
		}
	)
	author: User;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	@Sortable
	created: string;

	warnings?: Warning[];
	files?: File[];

	type: 'Tactic';

	@Column('jsonb', { nullable: true })
	plannedOffersData?: any;

	public toPublic(include: string[] = [], exclude: Array<keyof PublicTactic> = [], readOnly: boolean = false): PublicTactic {
		const pub: Partial<PublicTactic> = {
			id: this.id,
			programId: this.programId,
			name: this.name,
			detail: this.detail,
			start: this.start,
			end: this.end,
			lastCouponExpiration: this.lastCouponExpiration,
			destinationURL: this.destinationURL,
			commercePlatformWorkflow: this.commercePlatformWorkflow,
			created: this.created,
			tacticTypeId: this.tacticTypeId,
			fundingSourceId: this.fundingSourceId,
			locationId: this.locationId,
			rmn: this.rmn,
			dueDate: this.dueDate,
			nextSteps: this.nextSteps,
			buySpecs: this.buySpecs,
			flowchart: this.flowchart,
			landingPage: this.landingPage,
			readOnly,
			plannedOffersData: this.plannedOffersData
		};

		if(exclude?.includes('readOnly')) {
			delete pub.readOnly;
		}

		if(this.program && !exclude.includes('program')) {
			pub.program = new Program(this.program).toPublic();
		}

		if(this.tacticType && !exclude.includes('tacticType')) {
			pub.tacticType = new TacticType(this.tacticType).toPublic();
		}

		if(this.tacticPhase && !exclude.includes('tacticPhase')) {
			pub.tacticPhase = new TacticPhase(this.tacticPhase).toPublic();
		}

		if(this.fundingSource && !exclude.includes('fundingSource')) {
			pub.fundingSource = new FundingSource(this.fundingSource).toPublic();
		}

		if(this.location && !exclude.includes('location')) {
			pub.location = new Location<any>(this.location).toPublic();
		}

		if(this.author && !exclude.includes('author')) {
			pub.author = new User(this.author).toPublic();
		}

		if(this.brands?.length && !exclude.includes('brands')) {
			pub.brands = (this.brands as Partial<Brand>[])
				.map(b => {
					return new Brand(b).toPublic();
				});
		}

		if(this.vendors?.length && !exclude.includes('vendors')) {
			pub.vendors = (this.vendors as Partial<Vendor>[])
				.map(v => {
					return new Vendor(v).toPublic();
				});
		}

		if(this.invoices?.length && !exclude.includes('invoices')) {
			pub.invoices = (this.invoices as Partial<Invoice>[])
				.map(i => {
					return new Invoice(i).toPublic();
				});
		}

		if(this.costs?.length && !exclude.includes('costs')) {
			pub.costs = (this.costs as Partial<Cost>[])
				.filter(c => !c.deleted)
				.map(c => {
					return new Cost(c).toPublic();
				});
		}

		if(this.budgetCache && !exclude.includes('budgetCache')) {
			pub.budgetCache = new BudgetCache(this.budgetCache).toPublic();
		}

		if(this.brandCaches && !exclude.includes('brandCaches')) {
			pub.brandCaches = this.brandCaches.map(bc => new BudgetCache(bc).toPublic());
		}

		if(this.budgetCacheBrand && !exclude.includes('budgetCacheBrand')) {
			pub.budgetCacheBrand = this.budgetCacheBrand;
		}

		if(this.offers?.length && !exclude.includes('offers')) {
			pub.offers = (this.offers as Partial<Offer>[])
				.map(o => {
					return new Offer(o).toPublic();
				});
		}

		if(this.externalIds && !exclude.includes('externalIds')) {
			pub.externalIds = (this.externalIds as Partial<ExternalId>[])
				.map(i => {
					return new ExternalId(i).toPublic();
				});
		}

		if(this.notes?.length && !exclude.includes('notes')) {
			pub.notes = (this.notes as Partial<Note>[])
				.map(n => {
					return new Note(n).toPublic();
				});
		}

		if(this.tags?.length && !exclude.includes('tags')) {
			pub.tags = (this.tags as Partial<Tag>[])
				.map(t => {
					return new Tag(t).toPublic();
				});
		}

		if(this.measurements && !exclude.includes('measurements')) {
			pub.measurements = (this.measurements as Partial<Measurement>[])
				.map(m => {
					return new Measurement(m).toPublic();
				});
		}

		if(this.warnings?.length && !exclude.includes('warnings')) {
			pub.warnings = (this.warnings as Partial<Warning>[])
				.map(w => {
					return new Warning(w).toPublic();
				});
		}

		if(this.files?.length && !exclude.includes('files')) {
			pub.files = (this.files as Partial<File>[])
				.map(f => {
					return new File(f).toPublic();
				});
		}

		if(this.milestones?.length && !exclude.includes('milestone')) {
			pub.milestone = (this.milestones as Partial<Milestone>[])
				.map(i => {
					return new Milestone(i).toPublic();
				});
		}

		return pub as PublicTactic;
	}
}
