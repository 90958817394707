import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { isArray } from 'class-validator';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumber } from '../../../_core/utils/types.utils';
import { TableCollection } from '../../table/table.model';
import { BrandStrategy } from '../brand/brand.model';
import { Plan } from '../plan/plan.model';
import { Tactic } from '../tactic/tactic.model';
import { TacticQuery } from '../tactic/tactic.query';
import { BudgetRecommendation, Program } from './program.model';
import { ProgramQuery } from './program.query';
import { ProgramStore } from './program.store';

/**
 * Program Service
 * This service is responsible for the program logic and API calls.
 */
@Injectable({ providedIn: 'root' })
export class ProgramService {
	constructor(
		private programQuery: ProgramQuery,
		private programStore: ProgramStore,
		private http: HttpClient,
		private tacticQuery: TacticQuery
	) {}

	/**
	 * Get a list of programs for an organization Id
	 */
	get() {
		this.programStore.setLoading(true);
		return this.http.get<Program[]>(`${environment.apiUrl}/organization/${environment.organizationId}/program`).pipe(
			tap(programs => {
				console.log('Programs: get()', programs);
				this.programStore.set(this.prepareForAkita(programs));
				this.programStore.setLoading(false);
			})
		);
	}

	/**
	 * Get a single program from the API based on a program id.
	 */
	getOne(id: Program['id'], skipCache?: boolean) {
		this.programStore.setLoading(true);

		// Return cache if we already have this program
		// TODO: How do we determine if we have a full program

		if (!skipCache) {
			const programCache = this.programStore.getValue().entities[id];
			if (programCache) {
				this.programStore.setLoading(false);
				console.log('Loaded program from cache', programCache);
				return of(programCache);
			}
		}

		return this.http.get<Program>(`${environment.apiUrl}/organization/${environment.organizationId}/program/${id}`).pipe(
			tap(program => {
				console.log('Programs: getOne()', program);
				this.programStore.upsert(program.id, { ...program, type: 'Program' });
				this.programStore.setLoading(false);
			})
		);
	}

	/**
	 * Get an array of program from the API based on a program budgetPeriodId.
	 */
	getProgramsByBudgetPeriodId(budgetPeriodId: string, retailerId: string, name?: string) {
		//this.programStore.setLoading(true);

		// return this.http.get<Program[]>(`${environment.apiUrl}/organization/${environment.organizationId}/program/${budgetPeriodId}/budget`).pipe(
		// 	tap(programs => {
		// 		// console.log('Programs: getProgramsByBudgetPeriodId()', programs);
		// 		// this.programStore.set(programs);
		// 		this.programStore.setLoading(false);
		// 	})
		// );

		const apiReadyParams = {
			budgetPeriodId,
			retailerIds: [retailerId],
			name,
			include: []
		};
		return this.http
			.post<TableCollection<Program>>(`${environment.apiUrl}/organization/${environment.organizationId}/find/programs`, {
				...apiReadyParams
			})
			.pipe(
				tap(response => {
					console.log('getProgramsByBudgetPeriodId', response);
					// this.programStore.set(programs);
					//this.programStore.setLoading(false);
				})
			);
	}

	/**
	 * Set the akita store to the given programs.
	 */
	set(programs: Program[]) {
		this.programStore.set(programs);
	}

	/**
	 * Create a program on the API.
	 */
	create(program: Program) {
		return this.http
			.post<Program>(`${environment.apiUrl}/organization/${environment.organizationId}/program`, this.prepareForApi(program))
			.pipe(
				/* catchError(err => {
					return throwError({ message: 'You have read only access. If you need to create a program, please contact your administrator' });
				}), */
				tap(response => {
					console.log('create ',response);
					this.programStore.upsert(response.id, { ...response });
					this.programStore.remove(this.programQuery.getActiveId());
					this.programStore.setActive(response.id);
				})
			);
	}

	/**
	 * Add a program to the Akita Store
	 */
	add(program: Program, prepend = false) {
		this.programStore.add(program, { prepend });
	}

	/**
	 * Update a program on the API.
	 * If the 'skipHTTP' param is passed, it will just be updated on the Akita store.
	 */
	update(id, program: Partial<Program>, skipHTTP?: boolean) {
		// console.log('Trying to update Program', id, program);
		if (skipHTTP) {
			this.programStore.upsert(id, { ...program });
			return EMPTY;
		}

		if (program.hasOwnProperty('recommendations')) {
			this.programStore.update(id, { ...program, loadingRecommendations: true });
		}

		if (program.hasOwnProperty('keyLearnings')) {
			this.programStore.update(id, { ...program, loadingKeyLearnings: true });
		}

		return this.http
			.put<Program>(`${environment.apiUrl}/organization/${environment.organizationId}/program/${id}`, this.prepareForApi(program))
			.pipe(
				tap(response => {
					console.log('update ',response);
					this.programStore.upsert(response.id, { ...response, loadingKeyLearnings: false, loadingRecommendations: false });
				})
			);
	}

	/**
	 * Update the active program on the Akita store
	 */
	updateActive(program: Partial<Program>) {
		console.log('Trying to update active program', program);
		this.programStore.updateActive(program);
	}

	/**
	 * Upsert a tactic into the currently active program in the Akita store.
	 */
	upsertTacticIntoActive(tactic: Tactic) {
		const activeProgram = this.programQuery.getActive();
		this.programStore.updateActive({
			...activeProgram,
			tactics: [...activeProgram.tactics.filter(t => t.id !== tactic.id), tactic]
		});
	}

	/**
	 * Remove a tactic into the currently active program in the Akita store.
	 */
	removeTacticFromActive(tacticId: Tactic['id']) {
		const activeProgram = this.programQuery.getActive();
		this.programStore.updateActive({
			...activeProgram,
			tactics: [...activeProgram.tactics.filter(t => t.id !== tacticId)]
		});
	}

	/**
	 * Try to clone a project on the API
	 */
	clone(id: Program['id']) {
		return this.http.post<Program>(`${environment.apiUrl}/organization/${environment.organizationId}/program/${id}/clone`, {}).pipe(
			tap(response => {
				// We have a new program, let's add to the Akita store.
				this.programStore.upsert(response.id, { ...response });
			})
		);
	}

	/**
	 * Remove a program from the API
	 * If the 'skipHTTP' param is passed, it will just be removed on the Akita store.
	 */
	remove(id: Program['id'], planId?: Plan['id'], skipHTTP?: boolean) {
		if (skipHTTP) {
			this.programStore.remove(id);
			return EMPTY;
		}

		let url = `${environment.apiUrl}/organization/${environment.organizationId}/program/${id}`;

		if (planId) {
			url = `${environment.apiUrl}/organization/${environment.organizationId}/plan/${planId}/program/${id}`;
		}

		return this.http.delete<string>(url).pipe(
			tap(response => {
				this.programStore.remove(id);
			})
		);
	}

	/**
	 * Restore a program from the API
	 *
	 */
	restore(id: Program['id']) {
		return this.http.post<Program>(`${environment.apiUrl}/organization/${environment.organizationId}/program/${id}/restore`, {}).pipe(
			tap(response => {
				// We have a new program, let's add to the Akita store.
				this.programStore.upsert(response.id, { ...response });
			})
		);
	}

	/**
	 * Set a program as 'active' in the Akita store by id.
	 */
	setActive(id: Program['id']) {
		this.programStore.setActive(id);
	}

	/**
	 * Remove the 'active' state from a program in the Akita store by id.
	 */
	removeActive(id: Program['id']) {
		this.programStore.removeActive(id);
	}

	/**
	 * Set the loading state of the Akita store.
	 */
	setLoading(state: boolean) {
		this.programStore.setLoading(state);
	}

	/**
	 * Get a default program Form Builder object with default values set.
	 */
	getFormObject(program: Program, controlOverrides: any = {}) {
		return {
			name: [program.name, Validators.required],
			atRisk: [program.atRisk],
			budgetPeriod: [program.budgetPeriod, Validators.required],
			retailer: [program.retailer],
			agency: [program.agency],
			location: [program.location],
			programSector: [program.programSector, Validators.required],
			programUtilization: [program.programUtilization],
			programType: [program.programType],
			programPhase: [program.programPhase, Validators.required],
			planId: [program.planId],
			brands: [program.brands || [], Validators.required],
			brandStrategy: [program.brandStrategy],
			customerStrategy: [program.customerStrategy],
			description: [program.description],
			goal: [program.goal],
			start: [program.start, Validators.required],
			end: [program.end, Validators.required],
			brandInitiative: [program.brandInitiative],
			products: [program.products || []],
			owners: [program.owners || []],
			tags: [program.tags || []],
			investmentRecap: [program.investmentRecap || false],
			notes: [program.notes || []],
			objectives: [program.objectives],
			budgetAllocations: [program.budgetAllocations || []],
			commercePlatformWorkflow: [program.commercePlatformWorkflow],
			...controlOverrides
		};
	}

	/**
	 * Get any budget recommendations that are active from the Size of Prize module's property on the program.
	 */
	getActiveBudgetRecommendation(budgetRecommendation: BudgetRecommendation) {
		// console.log(
		// 	'Reco',
		// 	budgetRecommendation.entities.find(e => e.id === budgetRecommendation.active)
		// );
		return budgetRecommendation.entities.find(e => e.id === budgetRecommendation.active);
	}

	getRemainingBudget(program: Program, amountSuffix = 'Planned') {
		const costs = program.tactics
			.map(t => t.costs)
			.reduce((acc, val) => acc.concat(val), [])
			.reduce((a, b) => a + (Number(b?.['amount' + amountSuffix]) || 0), 0);

		return Number(program.budgetCache?.amountActual) - costs;
	}

	prepareForAkita(items: Program[]) {
		return items.map(item => ({
			...item,
			type: 'Program'
		}));
	}

	/**
	 * Normalize a program for the API.
	 */
	prepareForApi(program: Partial<Program>) {
		const obj = {};
		console.log('Preparing for API', program);

		if (program) {
			Object.keys(program).forEach(key => {
				switch (key) {
					case 'budgetPeriod':
					case 'programSector':
					case 'programPhase':
					case 'programType':
					case 'programUtilization':
					case 'location':
					case 'agency':
					case 'retailer':
						if (isArray(program[key])) {
							obj[key + 'Id'] = program[key]?.[0]?.id;
						} else if (program[key] === null) {
							obj[key + 'Id'] = null;
						} else {
							obj[key + 'Id'] = program[key]?.id;
						}
						break;

					case 'brandInitiative':
						const id = program[key]?.id;
						if (id) {
							obj[key + 'Id'] = id;
						} else {
							obj[key + 'Id'] = null;
						}
						break;

					case 'brands':
					case 'categories':
					case 'products':
					case 'owners':
						obj[key.slice(0, key.length - 1) + 'Ids'] = program[key]?.map(v => v.id);
						break;

					case 'programs':
						obj['ids'] = program[key]?.map(v => v.id);
						break;

					case 'tags':
						obj[key] = program[key]?.map(v => v.name);
						break;

					case 'objectives':
						if (program[key]) {
							delete program[key]['name'];
							delete program[key]['brand'];
							delete program[key]['endDate'];
							delete program[key]['startDate'];
							delete program[key]['undefined'];
						}

						obj['objectives'] = program[key]; // JSON.stringify(program[key]);
						break;

					case 'brandStrategy':
						// Detect whether brandStrategy is an array or open text, and package up for API as necessary.
						if (Array.isArray(program[key])) {
							let brandStrategies = program[key] as BrandStrategy[];
							obj['brandStrategyMerged'] = {
								// Careful here in case someone changes the label string on the component
								brandStrategy: brandStrategies.filter(strat => strat.name !== '(None)')
							};
						} else {
							let value: any = program[key];
							if (value) {
								if (!value?.['body']) {
									value = { body: value as string };
								}
								obj['brandStrategyMerged'] = {
									brandStrategyFreeform: value
								};
							}
						}
						break;

					case 'start':
						if (isNumber(program[key])) {
							obj[key] = new Date(program[key]).toISOString();
						} else {
							obj[key] = program[key];
						}

						const startOfDay = new Date(obj[key]);
						obj[key] = new Date(startOfDay.setHours(4, 0, 0, 0)).toISOString();
						break;

					case 'end':
						if (isNumber(program[key])) {
							obj[key] = new Date(program[key]).toISOString();
						} else {
							obj[key] = program[key];
						}

						const endOfDay = new Date(obj['end']);
						obj[key] = new Date(endOfDay.setHours(4, 0, 0, 0)).toISOString();
						break;
					case 'notes':
					case 'budgetAllocations':
					case 'jobCode':
						break;

					default:
						obj[key] = program[key];
						break;
				}
			});
		}        
		console.log('Preparing for API-after',obj);
		return obj;
	}

	/**
	 * Link program to another program
	 */
	linkProgramToAnotherProgram(id: string, parentId: string): Observable<[{ id: string; map: string }][]> {
		return this.http.post<[{ id: string; map: string }][]>(
			`${environment.apiUrl}/organization/${environment.organizationId}/program/${id}/parent/${parentId}`,
			{}
		);
	}

	/**
	 * Get linked programs
	 */
	getLinkedPrograms(programId: string) {
		return this.http.get<Program[]>(
			`${environment.apiUrl}/organization/${environment.organizationId}/program/${programId}/parent/suggest`
		);
	}

	/**
	 * Unlink linked program from another program
	 */
	unlinkProgramFromAnotherProgram(programId: string) {
		return this.http.post<Program>(
			`${environment.apiUrl}/organization/${environment.organizationId}/program/${programId}/unparent`,
			{}
		);
	}
}
