import {
	ActivateFilterCollection,
	FilterParameters,
	OverallFilterCollection,
	PlanningFilterCollection
} from '../entities/filter/filter.model';
import { InvoiceFilterCollection } from '../entities/invoice/invoice.model';
import { PlanDefaultActiveColumns, PlanFilterCollection } from '../entities/plan/plan.model';
import { ProgramDefaultActiveColumns, ProgramFilterCollection } from '../entities/program/program.model';
import { TacticFilterCollection } from '../entities/tactic/tactic.model';
import { BudgetCache } from '../global/global.model';

/**
 * A wrapper for budget caches that are used for chart endpoints.
 */
export interface BudgetCacheEntity {
	id: string;
	name: string;
	budgetCache: BudgetCache;
}

/**
 * Describes the different views that can be accessed on the overview pages.
 */
export type OverviewViewType = 'table' | 'calendar' | 'budgets' | 'media-planner' | 'files' | 'performance';

/**
 * Overview view model
 * Overview views describe the different contexts you can look at on the overview page.
 * Overview views are configured below.
 */
export interface OverviewView {
	id: string;
	name: string;
	icon: string;
	type: OverviewViewType;
}

/**
 * The current overview views.
 */
export const OverviewViews: OverviewView[] = [
	{
		id: 'table',
		type: 'table',
		name: 'Table View',
		icon: 'uil-table'
	},
	{
		id: 'calendar',
		type: 'calendar',
		name: 'Calendar View',
		icon: 'uil-calendar-alt'
	},
	{
		id: 'budgets',
		type: 'budgets',
		name: 'Budgets View',
		icon: 'uil-chart-pie'
	},
	{
		id: 'performance',
		type: 'performance',
		name: 'Performance View',
		icon: 'uil-analytics'
	},
	{
		id: 'media-planner',
		type: 'media-planner',
		name: 'Planning View (Beta)',
		icon: 'uil-vertical-distribution-center'
	},
	{
		id: 'files',
		type: 'files',
		name: 'File Explorer',
		icon: 'uil-images'
	}
];

/**
 * Default filter parameter values for the planning page
 * This applies on page load.
 */
export const PlanningDefaultFilterParams: FilterParameters = {
	include: {
		id: 'plans-programs',
		name: 'Plans + Planned Programs',
		value: {
			endpoint: 'plans',
			include: ['programs']
		}
	},
	groups: {
		id: 'retailers',
		name: 'Retailers',
		entityName: 'Retailer',
		value: 'retailers'
	}
};

/**
 * Default filter parameter values for the activate page
 * This applies on page load.
 */
export const ActivateDefaultFilterParams: FilterParameters = {
	include: {
		id: 'programs-tactics',
		name: 'Programs + Tactics',
		value: {
			endpoint: 'programs',
			include: ['tactics']
		}
	},
	groups: {
		id: 'brands',
		name: 'Brands',
		entityName: 'Brand',
		value: 'brands'
	}
};

/**
 * Config variables for the overview components on the Plan page
 */
export const PlanOverviewParams = {
	title: 'Plan',
	path: 'planning',
	section: 'planning',
	layoutLocationCode: 'tableView',
	entityName: 'plan',
	defaultFilterParameters: PlanningDefaultFilterParams,
	inlineFiltersKey: 'planInlineFilters',
	filters: [
		...OverallFilterCollection,
		...PlanningFilterCollection,
		...PlanFilterCollection,
		...ProgramFilterCollection,
		...TacticFilterCollection
	],
	defaultActiveColumns: PlanDefaultActiveColumns
};

/**
 * Config variables for the overview components on the Activate page
 */
export const ActivateOverviewParams = {
	title: 'Activate',
	path: 'activate',
	section: 'activation',
	layoutLocationCode: 'tableView',
	entityName: 'program',
	inlineFiltersKey: 'activateInlineFilters',
	defaultFilterParameters: ActivateDefaultFilterParams,
	filters: [
		...OverallFilterCollection,
		...ActivateFilterCollection,
		...PlanFilterCollection,
		...ProgramFilterCollection,
		...TacticFilterCollection,
		...InvoiceFilterCollection
	],
	defaultActiveColumns: ProgramDefaultActiveColumns
};
